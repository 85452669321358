import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import LinkButton from "./LinkButton";
import Button from "./Button";
import Input from "./Input";
import { getObjectFromArrayById } from "../../utils/common";
import OrderService from "../../services/OrderService";
import { getHrefs } from "../../utils/langService";

const useConfig = ({ accounts }) => {
  const createAccountsData = () => {
    const obj = {};
    accounts.forEach((elem, i) => (i > 0 ? (obj[elem.id] = 0) : (obj[elem.id] = 1)));
    return obj;
  };

  const [accountsData, setAccountsData] = useState(createAccountsData());
  const [price, setPrice] = useState(0);

  const updateAccountsData = e => {
    setAccountsData({ ...accountsData, [e.target.getAttribute("name")]: e.target.value });
  };

  useEffect(() => {
    let price = 0;

    Object.entries(accountsData).forEach(entry => {
      const [key, value] = entry;
      price += getObjectFromArrayById(accounts, key).price.year * Number(value);
    });
    setPrice(price);
  }, [accountsData, accounts]);

  return [accountsData, updateAccountsData, price];
};

const ProductBoxEmail = ({ children, t, lang, data, link }) => {
  const [accountsData, updateAccountsData, price] = useConfig({ accounts: data.accounts });

  const { pages } = getHrefs(lang);

  const addToShoppingCart = () => {
    const divide = [];
    let quantity = 0;
    Object.entries(accountsData).forEach(entry => {
      const [key, value] = entry;
      divide.push({ value: key, quantity: Number(value) });
      quantity += Number(value);
    });

    OrderService.createOrder({ productId: data.id, quantity, modifier: [], divide });
    navigate(`/${lang}/${pages[7]}/`);
  };

  return (
    <div className="ProductBox">
      <div>
        <div className={`ProductBox__header ${!children ? "ProductBox__header--withoutBorder" : ""}`}>
          <h4 className="ProductBox__title">{data.name}</h4>
          {!!t.description && <p className="ProductBox__description">{t.description}</p>}

          <div className="ProductBox__configBox">
            {data.accounts.map((elem, i) => (
              <Input
                key={i}
                type="number"
                name={elem.id}
                value={accountsData[elem.id]}
                onChange={updateAccountsData}
                min={0}
                labelText={`${t.accountText} ${elem.value}`}
              />
            ))}
          </div>
        </div>

        {!!children && <div className="ProductBox__children">{children}</div>}
      </div>

      <div className={`ProductBox__footer ${!children ? "ProductBox__footer--withoutBorder" : ""}`}>
        <div className="ProductBox__priceContainer">
          <p>{t.price.info}</p>
          <p className="ProductBox__price">
            <span>{price / 12}</span> {data.accounts[0].price.currency}
          </p>
          <p>{t.price.period}</p>
        </div>

        {!!link ? <LinkButton to={link}>{t.btn}</LinkButton> : <Button onClick={addToShoppingCart}>{t.btn}</Button>}
      </div>
    </div>
  );
};

ProductBoxEmail.propTypes = {
  children: PropTypes.node,
  t: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  link: PropTypes.string
};

export default ProductBoxEmail;
