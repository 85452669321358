import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

const Input = ({ labelText, errorMessage, ...attributes }) => {
  const [isFocused, setIsFocused] = useState(false);
  const { value } = attributes;

  useEffect(() => {
    if (!!value || value === 0 || value === "0") setIsFocused(true);
  }, [value]);

  const focused = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const blurred = useCallback(() => {
    if (value) return;
    setIsFocused(false);
  }, [value, setIsFocused]);

  return (
    <label className={`Input ${isFocused ? "focused" : ""} ${errorMessage ? "Input__error" : ""}`}>
      <p className="Input__labelText">{labelText}</p>
      <input {...attributes} onFocus={focused} onBlur={blurred} />
      {!!errorMessage && <p className="error">{errorMessage}</p>}
    </label>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  labelText: PropTypes.string,
  errorMessage: PropTypes.string
};

export default Input;
