import React from "react";
import PropTypes from "prop-types";

import imgCheck from "../../images/common/check.svg";

const PackagesTable = ({ t, packages }) => (
  <div className="PackagesTable">
    <h4 className="title">{t.title}</h4>
    <table>
      <thead>
        <tr>
          <th>&nbsp;</th>
          {packages.map((elem, i) => (
            <th key={i}>{elem.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {t.tbody.map((elem, i) => (
          <tr key={i}>
            {elem.map((element, index) => (
              <td key={index}>
                {index !== 0 && <p className="PackagesTable__mobileValue">{packages[index - 1].title}</p>}
                {typeof element === "boolean" ? element === true ? <img src={imgCheck} alt="Tak" /> : "-" : element}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    <div className="PackagesTable__stars">
      {t.stars.map((elem, i) => (
        <p key={i} className="star">
          {elem}
        </p>
      ))}
    </div>
  </div>
);

PackagesTable.propTypes = {
  t: PropTypes.object.isRequired,
  packages: PropTypes.array.isRequired
};

export default PackagesTable;
