import React from "react";
import PropTypes from "prop-types";
import PackagesTable from "../../../common/PackagesTable";

const EmailDetails = ({ t, packages }) => (
  <div className="Cloud__details">
    <PackagesTable t={t.maxSize} packages={packages} />
    <PackagesTable t={t.teamworkFunctions} packages={packages} />
    <PackagesTable t={t.functionalityAndLimits} packages={packages} />
    <PackagesTable t={t.security} packages={packages} />
  </div>
);

EmailDetails.propTypes = {
  t: PropTypes.object.isRequired,
  packages: PropTypes.array.isRequired
};

export default EmailDetails;
