import Email from "./Email";
import { connect } from "react-redux";
import { getEmailList } from "../../../../store/packages/email/actions";

const mapStateToProps = state => ({
  email: state.email
});

const mapDispatchToProps = dispatch => ({
  getEmailList: async () => dispatch(await getEmailList())
});

const EmailContainer = connect(mapStateToProps, mapDispatchToProps)(Email);
export default EmailContainer;
