import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../../../../layouts/Layout";
import Seo from "../../../seo";
import Intro from "../../../common/Intro";
import Breadcrumb from "../../../common/Breadcrumb";
import BasicSection from "../../../common/BasicSection";
import Advantages from "../../../common/Advantages";
import ProductBoxEmail from "../../../common/ProductBoxEmail";
import LabelText from "../../../common/LabelText";
import EmailDetails from "./EmailDetails";
import PackagesTable from "../../../common/PackagesTable";
import { getShareImage } from "../../../../utils/getShareImage";

import imgEmail from "../../../../images/email.jpg";
import imgEnvelope from "../../../../images/common/envelope.svg";
import imgReport from "../../../../images/common/report.svg";
import imgControl from "../../../../images/common/control.svg";

const Email = props => {
  const [details, setDetails] = useState(false);

  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;
  const { email } = props;

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} pages={[{ name: servicesT.cloud.title }]} />

      <div className="Cloud">
        <BasicSection t={t.mainSection} image={imgEmail}>
          <ul className="list-check">
            {t.mainSection.list.map((elem, i) => (
              <li key={i}>{elem}</li>
            ))}
          </ul>
        </BasicSection>

        <section className="section section--gray Cloud__packages">
          <div className="container">
            <h2 className="title">{t.packages.title}</h2>

            <div className="Cloud__additional__list">
              {email.data.map((elem, i) => (
                <ProductBoxEmail key={i} t={t.packages.list[i]} lang={lang} data={elem}>
                  <>
                    {t.packages.list[i].data.map((element, index) => (
                      <LabelText key={index} label={element.label} name={element.name} />
                    ))}
                  </>
                </ProductBoxEmail>
              ))}
            </div>
          </div>
        </section>

        <section className="section Cloud__detailed">
          <div className="container">
            <PackagesTable t={t.detailedSpecification.comparison} packages={t.packages.list} />

            <button className="link-blue link-blue--bold Cloud__detailed__btn" onClick={() => setDetails(!details)}>
              {!details ? t.detailedSpecification.btn.show : t.detailedSpecification.btn.hide}
            </button>

            {details && <EmailDetails t={t.detailedSpecification} packages={t.packages.list} />}
          </div>
        </section>

        <Advantages t={t.emailProAdvantages} images={[imgEnvelope, imgReport, imgControl]} columns3 nopadding />

        <section className="section section--gray Cloud__sectionList">
          <div className="container">
            <h2 className="title">{t.exchangeAdvantages.title}</h2>
            <ul className="list-check">
              {t.exchangeAdvantages.list.map((elem, i) => (
                <li key={i}>{elem}</li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  );
};

Email.propTypes = {
  pageContext: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  getEmailList: PropTypes.func.isRequired
};

export default Email;
